import PropTypes from 'prop-types';
import { IMAGE, VIDEO, SLIDESHOW } from '../../../../constants/mediaTypes';
import { IMAGES_ONLY, SLIDES } from '../../../../constants/slideshowTypes';

export const headerBackground = PropTypes.shape({
  // for legacy background prop
  image: PropTypes.string,
  video: PropTypes.string,
  oWidth: PropTypes.number,
  oHeight: PropTypes.number,
  alt: PropTypes.string,
  coordinates: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  editedAspectRatio: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  left: PropTypes.string,
  top: PropTypes.string,
  rotation: PropTypes.string,
  scale: PropTypes.number,
  position: PropTypes.string,
  overlayAlpha: PropTypes.number
});

export const headerImage = PropTypes.shape({
  image: PropTypes.string,
  oWidth: PropTypes.number,
  oHeight: PropTypes.number,
  alt: PropTypes.string,
  coordinates: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number
  }),
  editedAspectRatio: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
  left: PropTypes.string,
  top: PropTypes.string,
  rotation: PropTypes.string,
  scale: PropTypes.number,
  position: PropTypes.string,
  overlayAlpha: PropTypes.number
});

export const headerVideo = PropTypes.shape({
  image: PropTypes.string,
  video: PropTypes.string,
  oWidth: PropTypes.number,
  oHeight: PropTypes.number,
  alt: PropTypes.string,
  overlayAlpha: PropTypes.number
});

const headerSlideshow = PropTypes.shape({
  autoplay: PropTypes.bool,
  autoplayDelay: PropTypes.number,
  transition: PropTypes.oneOf(['fade', 'slide']),
  type: PropTypes.oneOf([IMAGES_ONLY, SLIDES]),
  dots: PropTypes.bool,
  arrows: PropTypes.bool,
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: headerImage,
      heroContent: PropTypes.shape({
        tagline: PropTypes.string,
        tagline2: PropTypes.string,
        cta: PropTypes.object
      })
    })
  ),
  alignmentOption: PropTypes.string,
  themeConfig: PropTypes.shape({
    height: PropTypes.string,
    maxWidthPercent: PropTypes.number
  })
});

export const headerMediaData = PropTypes.shape({
  mediaType: PropTypes.oneOf([IMAGE, VIDEO, SLIDESHOW]).isRequired,
  background: headerImage,
  video: headerVideo,
  slideshow: headerSlideshow
});
